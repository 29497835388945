const {
	href, origin, pathname, search
} = window.location;

// Normalize the current path by ensuring that the `search` part is always included
const currentPath = pathname + search;


const redirects = new Map([
	["/?rCH=2", "/indexpage"],
	["/agb-s", "/agb"],
	["/agb-s?rCH=2", "/agb"],
	["/anfahrt?rCH=2", "/anfahrt"],
	["/component/booking/?view=booking&id=108", "/Ostsee/Usedom/Karlshagen/163.-.DH.-.Laerchenweg.8.-.6-0"],
	["/component/booking/?view=booking&id=108&rCH=2", "/Ostsee/Usedom/Karlshagen/163.-.DH.-.Laerchenweg.8.-.6-0"],
	["/component/booking/?view=booking&id=117", "/Ostsee/Usedom/Karlshagen/170.-.DH.-.Kiefernweg.3.-.6-0"],
	["/component/booking/?view=booking&id=117&rCH=2", "/Ostsee/Usedom/Karlshagen/170.-.DH.-.Kiefernweg.3.-.6-0"],
	["/component/booking/?view=booking&id=118", "/Ostsee/Usedom/Karlshagen/171.-.DH.-.Kiefernweg.1.-.6-0"],
	["/component/booking/?view=booking&id=118&rCH=2", "/Ostsee/Usedom/Karlshagen/171.-.DH.-.Kiefernweg.1.-.6-0"],
	["/component/booking/?view=booking&id=130", "/Ostsee/Usedom/Karlshagen/118.-.DH.-.Birkenweg.2.b.-.4-2"],
	["/component/booking/?view=booking&id=130&rCH=2", "/Ostsee/Usedom/Karlshagen/118.-.DH.-.Birkenweg.2.b.-.4-2"],
	["/component/booking/?view=booking&id=131", "/Ostsee/Usedom/Karlshagen/119.-.DH.-.Birkenweg.2.a.-.6-0"],
	["/component/booking/?view=booking&id=131&rCH=2", "/Ostsee/Usedom/Karlshagen/119.-.DH.-.Birkenweg.2.a.-.6-0"],
	["/component/booking/?view=booking&id=59", "/Ostsee/Usedom/Karlshagen/37.-.DH.-..Heideweg.8..-..4-0"],
	["/component/booking/?view=booking&id=59&rCH=2", "/Ostsee/Usedom/Karlshagen/37.-.DH.-..Heideweg.8..-..4-0"],
	["/component/booking/?view=booking&id=60", "/Ostsee/Usedom/Karlshagen/38.-.DH.-.Heideweg.6..-..4-0"],
	["/component/booking/?view=booking&id=60&rCH=2", "/Ostsee/Usedom/Karlshagen/38.-.DH.-.Heideweg.6..-..4-0"],
	["/component/booking/?view=booking&id=61", "/Ostsee/Usedom/Karlshagen/66.-.DH.-.Sanddornweg.5.b.-.4-0"],
	["/component/booking/?view=booking&id=61&rCH=2", "/Ostsee/Usedom/Karlshagen/66.-.DH.-.Sanddornweg.5.b.-.4-0"],
	["/component/booking/?view=booking&id=62", "/Ostsee/Usedom/Karlshagen/97.-.DH.-.Ginsterweg.7.a.-.4-2"],
	["/component/booking/?view=booking&id=62&rCH=2", "/Ostsee/Usedom/Karlshagen/97.-.DH.-.Ginsterweg.7.a.-.4-2"],
	["/component/booking/?view=booking&id=63", "/Ostsee/Usedom/Karlshagen/98.-.DH.-.Ginsterweg.7.b.-.6-0"],
	["/component/booking/?view=booking&id=63&rCH=2", "/Ostsee/Usedom/Karlshagen/98.-.DH.-.Ginsterweg.7.b.-.6-0"],
	["/component/booking/?view=booking&id=64", "/Ostsee/Usedom/Karlshagen/113.-.DH.-.Birkenweg.6.a.-.4-1"],
	["/component/booking/?view=booking&id=64&rCH=2", "/Ostsee/Usedom/Karlshagen/113.-.DH.-.Birkenweg.6.a.-.4-1"],
	["/component/booking/?view=booking&id=66", "/Ostsee/Usedom/Karlshagen/146.-.DH.-.Kiefernweg.8.-.6-0"],
	["/component/booking/?view=booking&id=66&rCH=2", "/Ostsee/Usedom/Karlshagen/146.-.DH.-.Kiefernweg.8.-.6-0"],
	["/component/booking/?view=booking&id=67", "/Ostsee/Usedom/Karlshagen/149.-.DH.-.Kiefernweg.2.b.-.5-0"],
	["/component/booking/?view=booking&id=67&rCH=2", "/Ostsee/Usedom/Karlshagen/149.-.DH.-.Kiefernweg.2.b.-.5-0"],
	["/component/booking/?view=booking&id=68", "/Ostsee/Usedom/Karlshagen/150.-.DH.-.Kiefernweg.2.a.-.4-1"],
	["/component/booking/?view=booking&id=68&rCH=2", "/Ostsee/Usedom/Karlshagen/150.-.DH.-.Kiefernweg.2.a.-.4-1"],
	["/component/booking/?view=booking&id=69", "/Ostsee/Usedom/Karlshagen/159.-.DH.-.Kiefernweg.11.-.6-0"],
	["/component/booking/?view=booking&id=69&rCH=2", "/Ostsee/Usedom/Karlshagen/159.-.DH.-.Kiefernweg.11.-.6-0"],
	["/component/booking/?view=booking&id=99", "/Ostsee/Usedom/Karlshagen/120.-.DH.-.Birkenweg.1.a.-..6-0"],
	["/component/booking/?view=booking&id=99&rCH=2", "/Ostsee/Usedom/Karlshagen/120.-.DH.-.Birkenweg.1.a.-..6-0"],
	["/component/booking/?view=category&id=22", "/s#?p_15419_id=6"],
	["/component/booking/?view=category&id=22&rCH=2", "/s#?p_15419_id=6"],
	["/component/booking/?view=category&id=31", "/s#?p_15419_id=8"],
	["/component/booking/?view=category&id=31&rCH=2", "/s#?p_15419_id=8"],
	["/datenschutz?rCH=2", "/datenschutz"],
	["/duenenresidenz?rCH=2", "/Duenenresidenz"],
	["/duenenresidenz/?option=com_booking&view=booking&id=139", "/Ostsee/Usedom/Karlshagen/3.-.F2.-.Strandstrasse.16.-.4-1"],
	["/duenenresidenz/?option=com_booking&view=booking&id=139&rCH=2", "/Ostsee/Usedom/Karlshagen/3.-.F2.-.Strandstrasse.16.-.4-1"],
	["/duenenresidenz/?option=com_booking&view=booking&id=142", "/Ostsee/Usedom/Karlshagen/17-4.-.MA.-.Weidenweg.22.d.-4-0"],
	["/duenenresidenz/?option=com_booking&view=booking&id=142&rCH=2", "/Ostsee/Usedom/Karlshagen/17-4.-.MA.-.Weidenweg.22.d.-4-0"],
	["/duenenresidenz/?option=com_booking&view=booking&id=15", "/Ostsee/Usedom/Karlshagen/25-1.-.F2.-.Weidenweg.5.a.-.4-0"],
	["/duenenresidenz/?option=com_booking&view=booking&id=15&rCH=2", "/Ostsee/Usedom/Karlshagen/25-1.-.F2.-.Weidenweg.5.a.-.4-0"],
	["/duenenresidenz/?option=com_booking&view=booking&id=47", "/Ostsee/Usedom/Karlshagen/7-6.-.MA.-.Weidenweg.14.f.-.4-1"],
	["/duenenresidenz/?option=com_booking&view=booking&id=47&rCH=2", "/Ostsee/Usedom/Karlshagen/7-6.-.MA.-.Weidenweg.14.f.-.4-1"],
	["/duenenresidenz/doppelhaushaelfte-superior", "/s#?unitgroupid=48543&p_15419_id=7"],
	["/duenenresidenz/doppelhaushaelfte-superior?rCH=2", "/s#?unitgroupid=48543&p_15419_id=7"],
	["/duenenresidenz/doppelhaushaelfte-superior?view=booking&id=134", "/Ostsee/Usedom/Karlshagen/145-10...-.DH.-.Kiefernweg.10.-..8-0"],
	["/duenenresidenz/doppelhaushaelfte-superior?view=booking&id=134&rCH=2", "/Ostsee/Usedom/Karlshagen/145-10...-.DH.-.Kiefernweg.10.-..8-0"],
	["/duenenresidenz/doppelhaushaelfte-superior?view=booking&id=135", "/Ostsee/Usedom/Karlshagen/145-20.-.DH.-.Kiefernweg.10.a.-.8-0"],
	["/duenenresidenz/doppelhaushaelfte-superior?view=booking&id=135&rCH=2", "/Ostsee/Usedom/Karlshagen/145-20.-.DH.-.Kiefernweg.10.a.-.8-0"],
	["/duenenresidenz/doppelhaushaelfte-superior/doppelhaus-nr-145-10", "/Ostsee/Usedom/Karlshagen/145-10...-.DH.-.Kiefernweg.10.-..8-0"],
	["/duenenresidenz/doppelhaushaelfte-superior/doppelhaus-nr-145-10?rCH=2", "/Ostsee/Usedom/Karlshagen/145-10...-.DH.-.Kiefernweg.10.-..8-0"],
	["/duenenresidenz/doppelhaushaelfte-superior/doppelhaus-nr-145-20", "/Ostsee/Usedom/Karlshagen/145-20.-.DH.-.Kiefernweg.10.a.-.8-0"],
	["/duenenresidenz/doppelhaushaelfte-superior/doppelhaus-nr-145-20?rCH=2", "/Ostsee/Usedom/Karlshagen/145-20.-.DH.-.Kiefernweg.10.a.-.8-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung", "/s#?p_15419_id=4"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?rCH=2", "/s#?p_15419_id=4"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=132", "/Ostsee/Usedom/Karlshagen/99-7.-.DG.-.Ginsterweg.6.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=132&rCH=2", "/Ostsee/Usedom/Karlshagen/99-7.-.DG.-.Ginsterweg.6.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=39", "/Ostsee/Usedom/Karlshagen/44-5.-.DG.-.Heideweg.1.e.-..4-1"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=39&rCH=2", "/Ostsee/Usedom/Karlshagen/44-5.-.DG.-.Heideweg.1.e.-..4-1"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=40", "/Ostsee/Usedom/Karlshagen/54-7.-.DG.-.Lindenweg.2.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=40&rCH=2", "/Ostsee/Usedom/Karlshagen/54-7.-.DG.-.Lindenweg.2.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=41", "/Ostsee/Usedom/Karlshagen/57-7.-.DG.-.Lindenweg.1.g.-.2-2"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=41&rCH=2", "/Ostsee/Usedom/Karlshagen/57-7.-.DG.-.Lindenweg.1.g.-.2-2"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=42", "/s"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=42&rCH=2", "/s"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=booking&id=44", "/Ostsee/Usedom/Karlshagen/104-5.-.DG.-.Ginsterweg.1.e.-.4-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=category&id=20", "/s#?p_15419_id=4"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung?view=category&id=20&rCH=2", "/s#?p_15419_id=4"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-104-5", "/Ostsee/Usedom/Karlshagen/104-5.-.DG.-.Ginsterweg.1.e.-.4-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-104-5?rCH=2", "/Ostsee/Usedom/Karlshagen/104-5.-.DG.-.Ginsterweg.1.e.-.4-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-44-5", "/Ostsee/Usedom/Karlshagen/44-5.-.DG.-.Heideweg.1.e.-..4-1"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-44-5?rCH=2", "/Ostsee/Usedom/Karlshagen/44-5.-.DG.-.Heideweg.1.e.-..4-1"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-54-7", "/Ostsee/Usedom/Karlshagen/54-7.-.DG.-.Lindenweg.2.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-54-7?rCH=2", "/Ostsee/Usedom/Karlshagen/54-7.-.DG.-.Lindenweg.2.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-57-7", "/Ostsee/Usedom/Karlshagen/57-7.-.DG.-.Lindenweg.1.g.-.2-2"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-57-7?rCH=2", "/Ostsee/Usedom/Karlshagen/57-7.-.DG.-.Lindenweg.1.g.-.2-2"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-69-5", "/s"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-69-5?rCH=2", "/s"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-99-7", "/Ostsee/Usedom/Karlshagen/99-7.-.DG.-.Ginsterweg.6.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-dachgeschosswohnung/ferienwohnung-99-7?rCH=2", "/Ostsee/Usedom/Karlshagen/99-7.-.DG.-.Ginsterweg.6.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte", "/s#?p_15419_id=6"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?rCH=2", "/s#?p_15419_id=6"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=59", "/Ostsee/Usedom/Karlshagen/37.-.DH.-..Heideweg.8..-..4-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=59&rCH=2", "/Ostsee/Usedom/Karlshagen/37.-.DH.-..Heideweg.8..-..4-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=60", "/Ostsee/Usedom/Karlshagen/38.-.DH.-.Heideweg.6..-..4-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=60&rCH=2", "/Ostsee/Usedom/Karlshagen/38.-.DH.-.Heideweg.6..-..4-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=62", "/Ostsee/Usedom/Karlshagen/97.-.DH.-.Ginsterweg.7.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=62&rCH=2", "/Ostsee/Usedom/Karlshagen/97.-.DH.-.Ginsterweg.7.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=63", "/Ostsee/Usedom/Karlshagen/98.-.DH.-.Ginsterweg.7.b.-.6-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=63&rCH=2", "/Ostsee/Usedom/Karlshagen/98.-.DH.-.Ginsterweg.7.b.-.6-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte?view=booking&id=66", "/Ostsee/Usedom/Karlshagen/146.-.DH.-.Kiefernweg.8.-.6-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-146", "/Ostsee/Usedom/Karlshagen/146.-.DH.-.Kiefernweg.8.-.6-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-146?rCH=2", "/Ostsee/Usedom/Karlshagen/146.-.DH.-.Kiefernweg.8.-.6-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-37", "/Ostsee/Usedom/Karlshagen/37.-.DH.-..Heideweg.8..-..4-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-37?rCH=2", "/Ostsee/Usedom/Karlshagen/37.-.DH.-..Heideweg.8..-..4-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-38", "/Ostsee/Usedom/Karlshagen/38.-.DH.-.Heideweg.6..-..4-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-97", "/Ostsee/Usedom/Karlshagen/97.-.DH.-.Ginsterweg.7.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-97?rCH=2", "/Ostsee/Usedom/Karlshagen/97.-.DH.-.Ginsterweg.7.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-98", "/Ostsee/Usedom/Karlshagen/98.-.DH.-.Ginsterweg.7.b.-.6-0"],
	["/duenenresidenz/ferienwohnung-doppelhaushaelfte/doppelhaus-98?rCH=2", "/Ostsee/Usedom/Karlshagen/98.-.DH.-.Ginsterweg.7.b.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus", "/s#?p_15419_id=9"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=140", "/Ostsee/Usedom/Karlshagen/107.-.EH.-.Wacholderweg.7.-.4-2"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=140&rCH=2", "/Ostsee/Usedom/Karlshagen/107.-.EH.-.Wacholderweg.7.-.4-2"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=71", "/Ostsee/Usedom/Karlshagen/46..-.EH.-.Lindenweg.7.-.6-4"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=71&rCH=2", "/Ostsee/Usedom/Karlshagen/46..-.EH.-.Lindenweg.7.-.6-4"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=72", "/Ostsee/Usedom/Karlshagen/50.-.EH.-.Sanddornweg.14.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=72&rCH=2", "/Ostsee/Usedom/Karlshagen/50.-.EH.-.Sanddornweg.14.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=73", "/Ostsee/Usedom/Karlshagen/75.-.EH.-.Ginsterweg.15.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=73&rCH=2", "/Ostsee/Usedom/Karlshagen/75.-.EH.-.Ginsterweg.15.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=74", "/Ostsee/Usedom/Karlshagen/78.-.EH.-.Ahornweg.9.-.6-1"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=77", "/Ostsee/Usedom/Karlshagen/126.-.EH.-.Kiefernweg.27.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=77&rCH=2", "/Ostsee/Usedom/Karlshagen/126.-.EH.-.Kiefernweg.27.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=78", "/Ostsee/Usedom/Karlshagen/130.-.EH.-.Kiefernweg.16.-.6-1"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=booking&id=78&rCH=2", "/Ostsee/Usedom/Karlshagen/130.-.EH.-.Kiefernweg.16.-.6-1"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=category&id=23", "/s#?p_15419_id=9"],
	["/duenenresidenz/ferienwohnung-einzelhaus?view=category&id=23&rCH=2", "/s#?p_15419_id=9"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-107", "/Ostsee/Usedom/Karlshagen/107.-.EH.-.Wacholderweg.7.-.4-2"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-107?rCH=2", "/Ostsee/Usedom/Karlshagen/107.-.EH.-.Wacholderweg.7.-.4-2"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-126", "/Ostsee/Usedom/Karlshagen/126.-.EH.-.Kiefernweg.27.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-126?rCH=2", "/Ostsee/Usedom/Karlshagen/126.-.EH.-.Kiefernweg.27.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-130", "/Ostsee/Usedom/Karlshagen/130.-.EH.-.Kiefernweg.16.-.6-1"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-130?rCH=2", "/Ostsee/Usedom/Karlshagen/130.-.EH.-.Kiefernweg.16.-.6-1"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-46", "/Ostsee/Usedom/Karlshagen/46..-.EH.-.Lindenweg.7.-.6-4"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-46?rCH=2", "/Ostsee/Usedom/Karlshagen/46..-.EH.-.Lindenweg.7.-.6-4"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-50", "/Ostsee/Usedom/Karlshagen/50.-.EH.-.Sanddornweg.14.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-50?rCH=2", "/Ostsee/Usedom/Karlshagen/50.-.EH.-.Sanddornweg.14.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-75", "/Ostsee/Usedom/Karlshagen/75.-.EH.-.Ginsterweg.15.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-75?rCH=2", "/Ostsee/Usedom/Karlshagen/75.-.EH.-.Ginsterweg.15.-.6-0"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-78", "/Ostsee/Usedom/Karlshagen/78.-.EH.-.Ahornweg.9.-.6-1"],
	["/duenenresidenz/ferienwohnung-einzelhaus/einzelhaus-78?rCH=2", "/Ostsee/Usedom/Karlshagen/78.-.EH.-.Ahornweg.9.-.6-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung", "/s#?p_15419_id=5"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?rCH=2", "/s#?p_15419_id=5"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=109", "/Ostsee/Usedom/Karlshagen/17-6.-.MA.Weidenweg.22.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=109&rCH=2", "/Ostsee/Usedom/Karlshagen/17-6.-.MA.Weidenweg.22.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=119", "/Ostsee/Usedom/Karlshagen/72-4.-.MA.-.Sanddornweg.2.g.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=119&rCH=2", "/Ostsee/Usedom/Karlshagen/72-4.-.MA.-.Sanddornweg.2.g.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=128", "/Ostsee/Usedom/Karlshagen/17-5.-.MA.-.Weidenweg.22.e-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=128&rCH=2", "/Ostsee/Usedom/Karlshagen/17-5.-.MA.-.Weidenweg.22.e-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=142", "/Ostsee/Usedom/Karlshagen/17-4.-.MA.-.Weidenweg.22.d.-4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=142&rCH=2", "/Ostsee/Usedom/Karlshagen/17-4.-.MA.-.Weidenweg.22.d.-4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=46", "/Ostsee/Usedom/Karlshagen/7-5.-.MA.-.Weidenweg.14.e.-.5-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=46&rCH=2", "/Ostsee/Usedom/Karlshagen/7-5.-.MA.-.Weidenweg.14.e.-.5-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=47", "/Ostsee/Usedom/Karlshagen/7-6.-.MA.-.Weidenweg.14.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=47&rCH=2", "/Ostsee/Usedom/Karlshagen/7-6.-.MA.-.Weidenweg.14.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=49", "/Ostsee/Usedom/Karlshagen/18-4.-.MA.-.Weidenweg.18.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=49&rCH=2", "/Ostsee/Usedom/Karlshagen/18-4.-.MA.-.Weidenweg.18.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=50", "/Ostsee/Usedom/Karlshagen/25-4..-.MA.-.Weidenweg.5.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=51", "/Ostsee/Usedom/Karlshagen/25-5.-.MA.-.Weidenweg.5.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=52", "/Ostsee/Usedom/Karlshagen/27-4.-.MA.-.Weidenweg.1.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=52&rCH=2", "/Ostsee/Usedom/Karlshagen/27-4.-.MA.-.Weidenweg.1.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=54", "/Ostsee/Usedom/Karlshagen/40-4.-.MA.-.Heideweg.2.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=55", "/Ostsee/Usedom/Karlshagen/40-5.-.MA.-.Heideweg.2.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=55&rCH=2", "/Ostsee/Usedom/Karlshagen/40-5.-.MA.-.Heideweg.2.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=56", "/Ostsee/Usedom/Karlshagen/40-6.-.MA.-.Heideweg.2.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=56&rCH=2", "/Ostsee/Usedom/Karlshagen/40-6.-.MA.-.Heideweg.2.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=58", "/Ostsee/Usedom/Karlshagen/71-4.-.MA.-.Sanddornweg.1.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=booking&id=58&rCH=2", "/Ostsee/Usedom/Karlshagen/71-4.-.MA.-.Sanddornweg.1.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=category&id=21", "/s#?p_15419_id=5"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung?view=category&id=21&rCH=2", "/s#?p_15419_id=5"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-17-4", "/Ostsee/Usedom/Karlshagen/17-4.-.MA.-.Weidenweg.22.d.-4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-17-4?rCH=2", "/Ostsee/Usedom/Karlshagen/17-4.-.MA.-.Weidenweg.22.d.-4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-17-5", "/Ostsee/Usedom/Karlshagen/17-5.-.MA.-.Weidenweg.22.e-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-17-5?rCH=2", "/Ostsee/Usedom/Karlshagen/17-5.-.MA.-.Weidenweg.22.e-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-17-6", "/Ostsee/Usedom/Karlshagen/17-6.-.MA.Weidenweg.22.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-17-6?rCH=2", "/Ostsee/Usedom/Karlshagen/17-6.-.MA.Weidenweg.22.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-18-4", "/Ostsee/Usedom/Karlshagen/18-4.-.MA.-.Weidenweg.18.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-18-4?rCH=2", "/Ostsee/Usedom/Karlshagen/18-4.-.MA.-.Weidenweg.18.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-25-4", "/Ostsee/Usedom/Karlshagen/25-4..-.MA.-.Weidenweg.5.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-25-4?rCH=2", "/Ostsee/Usedom/Karlshagen/25-4..-.MA.-.Weidenweg.5.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-25-5", "/Ostsee/Usedom/Karlshagen/25-5.-.MA.-.Weidenweg.5.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-25-5?rCH=2", "/Ostsee/Usedom/Karlshagen/25-5.-.MA.-.Weidenweg.5.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-27-4", "/Ostsee/Usedom/Karlshagen/27-4.-.MA.-.Weidenweg.1.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-27-4?rCH=2", "/Ostsee/Usedom/Karlshagen/27-4.-.MA.-.Weidenweg.1.d.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-40-4", "/Ostsee/Usedom/Karlshagen/40-4.-.MA.-.Heideweg.2.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-40-5", "/Ostsee/Usedom/Karlshagen/40-5.-.MA.-.Heideweg.2.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-40-5?rCH=2", "/Ostsee/Usedom/Karlshagen/40-5.-.MA.-.Heideweg.2.e.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-40-6", "/Ostsee/Usedom/Karlshagen/40-6.-.MA.-.Heideweg.2.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-40-6?rCH=2", "/Ostsee/Usedom/Karlshagen/40-6.-.MA.-.Heideweg.2.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-7-5", "/Ostsee/Usedom/Karlshagen/7-5.-.MA.-.Weidenweg.14.e.-.5-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-7-5?rCH=2", "/Ostsee/Usedom/Karlshagen/7-5.-.MA.-.Weidenweg.14.e.-.5-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-7-6", "/Ostsee/Usedom/Karlshagen/7-6.-.MA.-.Weidenweg.14.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-7-6?rCH=2", "/Ostsee/Usedom/Karlshagen/7-6.-.MA.-.Weidenweg.14.f.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-71-4", "/Ostsee/Usedom/Karlshagen/71-4.-.MA.-.Sanddornweg.1.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-71-4?rCH=2", "/Ostsee/Usedom/Karlshagen/71-4.-.MA.-.Sanddornweg.1.g.-.4-0"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-72-4", "/Ostsee/Usedom/Karlshagen/72-4.-.MA.-.Sanddornweg.2.g.-.4-1"],
	["/duenenresidenz/ferienwohnung-maisonettewohnung/ferienwohnung-72-4?rCH=2", "/Ostsee/Usedom/Karlshagen/72-4.-.MA.-.Sanddornweg.2.g.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer", "/s#?p_15419_id=1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?rCH=2", "/s#?p_15419_id=1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=105", "/Ostsee/Usedom/Karlshagen/69-1.-.F2.-.Sanddornweg.3.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=105&rCH=2", "/Ostsee/Usedom/Karlshagen/69-1.-.F2.-.Sanddornweg.3.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=12", "/Ostsee/Usedom/Karlshagen/15-3.-.F2.-.Weidenweg.4.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=12&rCH=2", "/Ostsee/Usedom/Karlshagen/15-3.-.F2.-.Weidenweg.4.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=124", "/Ostsee/Usedom/Karlshagen/52-6.-.F2.-.Lindenweg.6.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=124&rCH=2", "/Ostsee/Usedom/Karlshagen/52-6.-.F2.-.Lindenweg.6.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=13", "/Ostsee/Usedom/Karlshagen/17-3.-.F2.-.Weidenweg.22.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=13&rCH=2", "/Ostsee/Usedom/Karlshagen/17-3.-.F2.-.Weidenweg.22.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=138", "/Ostsee/Usedom/Karlshagen/69-3.-.F2.-.Sanddornweg.3.b.-.3-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=138&rCH=2", "/Ostsee/Usedom/Karlshagen/69-3.-.F2.-.Sanddornweg.3.b.-.3-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=14", "/Ostsee/Usedom/Karlshagen/18-2.-.F2.-.Weidenweg.18.b.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=14&rCH=2", "/Ostsee/Usedom/Karlshagen/18-2.-.F2.-.Weidenweg.18.b.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=15", "/Ostsee/Usedom/Karlshagen/25-1.-.F2.-.Weidenweg.5.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=15&rCH=2", "/Ostsee/Usedom/Karlshagen/25-1.-.F2.-.Weidenweg.5.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=17", "/Ostsee/Usedom/Karlshagen/43-1.-.F2.-.Heideweg.3.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=17&rCH=2", "/Ostsee/Usedom/Karlshagen/43-1.-.F2.-.Heideweg.3.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=18", "/Ostsee/Usedom/Karlshagen/43-3.-.F2.-.Heideweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=18&rCH=2", "/Ostsee/Usedom/Karlshagen/43-3.-.F2.-.Heideweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=19", "/Ostsee/Usedom/Karlshagen/43-5.-.F2.-.Heideweg.3.e.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=19&rCH=2", "/Ostsee/Usedom/Karlshagen/43-5.-.F2.-.Heideweg.3.e.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=20", "/Ostsee/Usedom/Karlshagen/44-2.-.F2.-.Heideweg.1.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=20&rCH=2", "/Ostsee/Usedom/Karlshagen/44-2.-.F2.-.Heideweg.1.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=21", "/Ostsee/Usedom/Karlshagen/44-3.-.F2.-.Heideweg.1.d.-.3-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=21&rCH=2", "/Ostsee/Usedom/Karlshagen/44-3.-.F2.-.Heideweg.1.d.-.3-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=22", "/Ostsee/Usedom/Karlshagen/52-3.-.F2.-.Lindenweg.6.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=22&rCH=2", "/Ostsee/Usedom/Karlshagen/52-3.-.F2.-.Lindenweg.6.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=24", "/Ostsee/Usedom/Karlshagen/69-2.-.F2.-.Sanddornweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=24&rCH=2", "/Ostsee/Usedom/Karlshagen/69-2.-.F2.-.Sanddornweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=27", "/Ostsee/Usedom/Karlshagen/87-3.-.F2.-.Ahornweg.3.b.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=27&rCH=2", "/Ostsee/Usedom/Karlshagen/87-3.-.F2.-.Ahornweg.3.b.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=28", "/Ostsee/Usedom/Karlshagen/104-2.-.F2.-.Ginsterweg.1.c.-.3-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=28&rCH=2", "/Ostsee/Usedom/Karlshagen/104-2.-.F2.-.Ginsterweg.1.c.-.3-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=94", "/Ostsee/Usedom/Karlshagen/101-4.-.F2.-.Ginsterweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=booking&id=94&rCH=2", "/Ostsee/Usedom/Karlshagen/101-4.-.F2.-.Ginsterweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=category&id=17", "/s#?p_15419_id=1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer?view=category&id=17&rCH=2", "/s#?p_15419_id=1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-101-4", "/Ostsee/Usedom/Karlshagen/101-4.-.F2.-.Ginsterweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-101-4?rCH=2", "/Ostsee/Usedom/Karlshagen/101-4.-.F2.-.Ginsterweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-104-2", "/Ostsee/Usedom/Karlshagen/104-2.-.F2.-.Ginsterweg.1.c.-.3-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-15-3", "/Ostsee/Usedom/Karlshagen/15-3.-.F2.-.Weidenweg.4.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-15-3?rCH=2", "/Ostsee/Usedom/Karlshagen/15-3.-.F2.-.Weidenweg.4.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-17-3", "/Ostsee/Usedom/Karlshagen/17-3.-.F2.-.Weidenweg.22.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-17-3?rCH=2", "/Ostsee/Usedom/Karlshagen/17-3.-.F2.-.Weidenweg.22.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-18-2", "/Ostsee/Usedom/Karlshagen/18-2.-.F2.-.Weidenweg.18.b.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-25-1", "/Ostsee/Usedom/Karlshagen/25-1.-.F2.-.Weidenweg.5.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-25-1?rCH=2", "/Ostsee/Usedom/Karlshagen/25-1.-.F2.-.Weidenweg.5.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-43-1", "/Ostsee/Usedom/Karlshagen/43-1.-.F2.-.Heideweg.3.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-43-1?rCH=2", "/Ostsee/Usedom/Karlshagen/43-1.-.F2.-.Heideweg.3.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-43-3", "/Ostsee/Usedom/Karlshagen/43-3.-.F2.-.Heideweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-43-3?rCH=2", "/Ostsee/Usedom/Karlshagen/43-3.-.F2.-.Heideweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-43-5", "/Ostsee/Usedom/Karlshagen/43-5.-.F2.-.Heideweg.3.e.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-43-5?rCH=2", "/Ostsee/Usedom/Karlshagen/43-5.-.F2.-.Heideweg.3.e.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-44-2", "/Ostsee/Usedom/Karlshagen/44-2.-.F2.-.Heideweg.1.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-44-2?rCH=2", "/Ostsee/Usedom/Karlshagen/44-2.-.F2.-.Heideweg.1.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-44-3", "/Ostsee/Usedom/Karlshagen/44-3.-.F2.-.Heideweg.1.d.-.3-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-44-3?rCH=2", "/Ostsee/Usedom/Karlshagen/44-3.-.F2.-.Heideweg.1.d.-.3-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-52-3", "/Ostsee/Usedom/Karlshagen/52-3.-.F2.-.Lindenweg.6.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-52-3?rCH=2", "/Ostsee/Usedom/Karlshagen/52-3.-.F2.-.Lindenweg.6.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-52-6", "/Ostsee/Usedom/Karlshagen/52-6.-.F2.-.Lindenweg.6.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-52-6?rCH=2", "/Ostsee/Usedom/Karlshagen/52-6.-.F2.-.Lindenweg.6.f.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-69-1", "/Ostsee/Usedom/Karlshagen/69-1.-.F2.-.Sanddornweg.3.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-69-1?rCH=2", "/Ostsee/Usedom/Karlshagen/69-1.-.F2.-.Sanddornweg.3.a.-.4-1"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-69-2", "/Ostsee/Usedom/Karlshagen/69-2.-.F2.-.Sanddornweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-69-2?rCH=2", "/Ostsee/Usedom/Karlshagen/69-2.-.F2.-.Sanddornweg.3.c.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-69-3", "/Ostsee/Usedom/Karlshagen/69-3.-.F2.-.Sanddornweg.3.b.-.3-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-69-3?rCH=2", "/Ostsee/Usedom/Karlshagen/69-3.-.F2.-.Sanddornweg.3.b.-.3-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-87-3", "/Ostsee/Usedom/Karlshagen/87-3.-.F2.-.Ahornweg.3.b.-.4-0"],
	["/duenenresidenz/ferienwohnung-mit-1-schlafzimmer-zusatzzimmer/ferienwohnung-87-3?rCH=2", "/Ostsee/Usedom/Karlshagen/87-3.-.F2.-.Ahornweg.3.b.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a", "/s#?p_15419_id=2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?rCH=2", "/s#?p_15419_id=2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=100", "/Ostsee/Usedom/Karlshagen/51-1.-.QA.-.Lindenweg.8.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=100&rCH=2", "/Ostsee/Usedom/Karlshagen/51-1.-.QA.-.Lindenweg.8.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=114", "/Ostsee/Usedom/Karlshagen/53-2.-.QA.-.Lindenweg.4.b.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=114&rCH=2", "/Ostsee/Usedom/Karlshagen/53-2.-.QA.-.Lindenweg.4.b.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=29", "/Ostsee/Usedom/Karlshagen/41-2.-.QA.-.Heideweg.7.b.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=29&rCH=2", "/Ostsee/Usedom/Karlshagen/41-2.-.QA.-.Heideweg.7.b.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=30", "/Ostsee/Usedom/Karlshagen/53-3.-.QA.-.Lindenweg.4.c.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=30&rCH=2", "/Ostsee/Usedom/Karlshagen/53-3.-.QA.-.Lindenweg.4.c.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=32", "/Ostsee/Usedom/Karlshagen/55-3..-.QA.-.Lindenweg.5.c.-.2-1"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=booking&id=32&rCH=2", "/Ostsee/Usedom/Karlshagen/55-3..-.QA.-.Lindenweg.5.c.-.2-1"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=category&id=18", "/s#?p_15419_id=2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a?view=category&id=18&rCH=2", "/s#?p_15419_id=2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-41-2", "/Ostsee/Usedom/Karlshagen/41-2.-.QA.-.Heideweg.7.b.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-41-2?rCH=2", "/Ostsee/Usedom/Karlshagen/41-2.-.QA.-.Heideweg.7.b.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-51-1", "/Ostsee/Usedom/Karlshagen/51-1.-.QA.-.Lindenweg.8.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-51-1?rCH=2", "/Ostsee/Usedom/Karlshagen/51-1.-.QA.-.Lindenweg.8.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-53-2", "/Ostsee/Usedom/Karlshagen/53-2.-.QA.-.Lindenweg.4.b.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-53-2?rCH=2", "/Ostsee/Usedom/Karlshagen/53-2.-.QA.-.Lindenweg.4.b.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-53-3", "/Ostsee/Usedom/Karlshagen/53-3.-.QA.-.Lindenweg.4.c.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-53-3?rCH=2", "/Ostsee/Usedom/Karlshagen/53-3.-.QA.-.Lindenweg.4.c.-.2-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-55-3", "/Ostsee/Usedom/Karlshagen/55-3..-.QA.-.Lindenweg.5.c.-.2-1"],
	["/duenenresidenz/ferienwohnung-quadro-typ-a/ferienwohnung-55-3?rCH=2", "/Ostsee/Usedom/Karlshagen/55-3..-.QA.-.Lindenweg.5.c.-.2-1"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b", "/s#?p_15419_id=3"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=111", "/Ostsee/Usedom/Karlshagen/68-1.-.QB.-.Sanddornweg.6.b.-.5-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=111&rCH=2", "/Ostsee/Usedom/Karlshagen/68-1.-.QB.-.Sanddornweg.6.b.-.5-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=133", "/Ostsee/Usedom/Karlshagen/102-4.-.QB.-.Ginsterweg.3.c.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=133&rCH=2", "/Ostsee/Usedom/Karlshagen/102-4.-.QB.-.Ginsterweg.3.c.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=34", "/Ostsee/Usedom/Karlshagen/56-1.-.QB.-.Lindenweg.3.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=34&rCH=2", "/Ostsee/Usedom/Karlshagen/56-1.-.QB.-.Lindenweg.3.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=35", "/Ostsee/Usedom/Karlshagen/68-2.-.QB.-.Sanddornweg.6.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=35&rCH=2", "/Ostsee/Usedom/Karlshagen/68-2.-.QB.-.Sanddornweg.6.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=36", "/Ostsee/Usedom/Karlshagen/68-3.-.QB.-.Sanddornweg.6.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=36&rCH=2", "/Ostsee/Usedom/Karlshagen/68-3.-.QB.-.Sanddornweg.6.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=37", "/Ostsee/Usedom/Karlshagen/70-1.-.QB.-.Sanddornweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=booking&id=37&rCH=2", "/Ostsee/Usedom/Karlshagen/70-1.-.QB.-.Sanddornweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=category&id=19", "/s#?p_15419_id=3"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b?view=category&id=19&rCH=2", "/s#?p_15419_id=3"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-102-4", "/Ostsee/Usedom/Karlshagen/102-4.-.QB.-.Ginsterweg.3.c.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-102-4?rCH=2", "/Ostsee/Usedom/Karlshagen/102-4.-.QB.-.Ginsterweg.3.c.-.2-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-56-1", "/Ostsee/Usedom/Karlshagen/56-1.-.QB.-.Lindenweg.3.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-56-1?rCH=2", "/Ostsee/Usedom/Karlshagen/56-1.-.QB.-.Lindenweg.3.a.-.4-2"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-68-1", "/Ostsee/Usedom/Karlshagen/68-1.-.QB.-.Sanddornweg.6.b.-.5-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-68-1?rCH=2", "/Ostsee/Usedom/Karlshagen/68-1.-.QB.-.Sanddornweg.6.b.-.5-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-68-2", "/Ostsee/Usedom/Karlshagen/68-2.-.QB.-.Sanddornweg.6.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-68-2?rCH=2", "/Ostsee/Usedom/Karlshagen/68-2.-.QB.-.Sanddornweg.6.a.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-68-3", "/Ostsee/Usedom/Karlshagen/68-3.-.QB.-.Sanddornweg.6.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-68-3?rCH=2", "/Ostsee/Usedom/Karlshagen/68-3.-.QB.-.Sanddornweg.6.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-70-1", "/Ostsee/Usedom/Karlshagen/70-1.-.QB.-.Sanddornweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-quadro-typ-b/ferienwohnung-70-1?rCH=2", "/Ostsee/Usedom/Karlshagen/70-1.-.QB.-.Sanddornweg.4.d.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus", "/s#?p_15419_id=10"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?rCH=2", "/s#?p_15419_id=10"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=107", "/Ostsee/Usedom/Karlshagen/161.-.RH.-.Laerchenweg.10.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=107&rCH=2", "/Ostsee/Usedom/Karlshagen/161.-.RH.-.Laerchenweg.10.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=110", "/Ostsee/Usedom/Karlshagen/167.-.RH.-.Kiefernweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=110&rCH=2", "/Ostsee/Usedom/Karlshagen/167.-.RH.-.Kiefernweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=112", "/Ostsee/Usedom/Karlshagen/172.-.RH.-.Laerchenweg.1.-.6-1"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=112&rCH=2", "/Ostsee/Usedom/Karlshagen/172.-.RH.-.Laerchenweg.1.-.6-1"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=113", "/Ostsee/Usedom/Karlshagen/164.-.RH.-.Kiefernweg.7.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=113&rCH=2", "/Ostsee/Usedom/Karlshagen/164.-.RH.-.Kiefernweg.7.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=82", "/Ostsee/Usedom/Karlshagen/90.-.RH.-.Ginsterweg.13.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=82&rCH=2", "/Ostsee/Usedom/Karlshagen/90.-.RH.-.Ginsterweg.13.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=83", "/Ostsee/Usedom/Karlshagen/94.-.RH.-.Ginsterweg.9.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=83&rCH=2", "/Ostsee/Usedom/Karlshagen/94.-.RH.-.Ginsterweg.9.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=84", "/Ostsee/Usedom/Karlshagen/124.-.RH.-.Kiefernweg.31.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=84&rCH=2", "/Ostsee/Usedom/Karlshagen/124.-.RH.-.Kiefernweg.31.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=85", "/Ostsee/Usedom/Karlshagen/125..-.RH.-.Kiefernweg.29.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=85&rCH=2", "/Ostsee/Usedom/Karlshagen/125..-.RH.-.Kiefernweg.29.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=86", "/Ostsee/Usedom/Karlshagen/128.-.RH.-.Wacholderweg.16..-..6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=86&rCH=2", "/Ostsee/Usedom/Karlshagen/128.-.RH.-.Wacholderweg.16..-..6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=87", "/Ostsee/Usedom/Karlshagen/136.-.RH.-.Wacholderweg.7.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=87&rCH=2", "/Ostsee/Usedom/Karlshagen/136.-.RH.-.Wacholderweg.7.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=88", "/Ostsee/Usedom/Karlshagen/138.-.RH.-.Wacholderweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=booking&id=88&rCH=2", "/Ostsee/Usedom/Karlshagen/138.-.RH.-.Wacholderweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=category&id=24", "/s#?p_15419_id=10"],
	["/duenenresidenz/ferienwohnung-reetdachhaus?view=category&id=24&rCH=2", "/s#?p_15419_id=10"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-124", "/Ostsee/Usedom/Karlshagen/124.-.RH.-.Kiefernweg.31.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-124?rCH=2", "/Ostsee/Usedom/Karlshagen/124.-.RH.-.Kiefernweg.31.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-125", "/Ostsee/Usedom/Karlshagen/125..-.RH.-.Kiefernweg.29.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-125?rCH=2", "/Ostsee/Usedom/Karlshagen/125..-.RH.-.Kiefernweg.29.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-128", "/Ostsee/Usedom/Karlshagen/128.-.RH.-.Wacholderweg.16..-..6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-128?rCH=2", "/Ostsee/Usedom/Karlshagen/128.-.RH.-.Wacholderweg.16..-..6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-136", "/Ostsee/Usedom/Karlshagen/136.-.RH.-.Wacholderweg.7.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-136?rCH=2", "/Ostsee/Usedom/Karlshagen/136.-.RH.-.Wacholderweg.7.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-138", "/Ostsee/Usedom/Karlshagen/138.-.RH.-.Wacholderweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-138?rCH=2", "/Ostsee/Usedom/Karlshagen/138.-.RH.-.Wacholderweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-161", "/Ostsee/Usedom/Karlshagen/161.-.RH.-.Laerchenweg.10.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-161?rCH=2", "/Ostsee/Usedom/Karlshagen/161.-.RH.-.Laerchenweg.10.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-164", "/Ostsee/Usedom/Karlshagen/164.-.RH.-.Kiefernweg.7.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-164?rCH=2", "/Ostsee/Usedom/Karlshagen/164.-.RH.-.Kiefernweg.7.-.6-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-167", "/Ostsee/Usedom/Karlshagen/167.-.RH.-.Kiefernweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-167?rCH=2", "/Ostsee/Usedom/Karlshagen/167.-.RH.-.Kiefernweg.5.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-172", "/Ostsee/Usedom/Karlshagen/172.-.RH.-.Laerchenweg.1.-.6-1"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-172?rCH=2", "/Ostsee/Usedom/Karlshagen/172.-.RH.-.Laerchenweg.1.-.6-1"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-90", "/Ostsee/Usedom/Karlshagen/90.-.RH.-.Ginsterweg.13.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-90?rCH=2", "/Ostsee/Usedom/Karlshagen/90.-.RH.-.Ginsterweg.13.-.4-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-94", "/Ostsee/Usedom/Karlshagen/94.-.RH.-.Ginsterweg.9.-.5-0"],
	["/duenenresidenz/ferienwohnung-reetdachhaus/reetdachhaus-94?rCH=2", "/Ostsee/Usedom/Karlshagen/94.-.RH.-.Ginsterweg.9.-.5-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?rCH=2", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=10", "/Ostsee/Usedom/Karlshagen/99-1.-.F1.-.Ginsterweg.6.a.-.2-2"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=10&rCH=2", "/Ostsee/Usedom/Karlshagen/99-1.-.F1.-.Ginsterweg.6.a.-.2-2"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=102", "/Ostsee/Usedom/Karlshagen/40-2.-.F1-.Heideweg.2.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=102&rCH=2", "/Ostsee/Usedom/Karlshagen/40-2.-.F1-.Heideweg.2.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=11", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=11&rCH=2", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=2", "/Ostsee/Usedom/Karlshagen/15-2.-.F1.-.Weidenweg.4.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=2&rCH=2", "/Ostsee/Usedom/Karlshagen/15-2.-.F1.-.Weidenweg.4.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=3", "/Ostsee/Usedom/Karlshagen/25-2.-.F1.-.Weidenweg.5.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=3&rCH=2", "/Ostsee/Usedom/Karlshagen/25-2.-.F1.-.Weidenweg.5.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=5", "/Ostsee/Usedom/Karlshagen/44-1.-.F1.-.Heideweg.1c.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=5&rCH=2", "/Ostsee/Usedom/Karlshagen/44-1.-.F1.-.Heideweg.1c.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=6", "/Ostsee/Usedom/Karlshagen/44-4.-.F1.-.Heideweg.1.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=6&rCH=2", "/Ostsee/Usedom/Karlshagen/44-4.-.F1.-.Heideweg.1.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=7", "/Ostsee/Usedom/Karlshagen/54-6.-.F2.-.Lindenweg.2.f.-.3-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=7&rCH=2", "/Ostsee/Usedom/Karlshagen/54-6.-.F2.-.Lindenweg.2.f.-.3-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=8", "/Ostsee/Usedom/Karlshagen/57-2.-.F1.-.Lindenweg.1.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=booking&id=8&rCH=2", "/Ostsee/Usedom/Karlshagen/57-2.-.F1.-.Lindenweg.1.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=category&id=16", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer?view=category&id=16&rCH=2", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-103-1", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-103-1?rCH=2", "/s#?p_15419_id=0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-15-2", "/Ostsee/Usedom/Karlshagen/15-2.-.F1.-.Weidenweg.4.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-15-2?rCH=2", "/Ostsee/Usedom/Karlshagen/15-2.-.F1.-.Weidenweg.4.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-25-2", "/Ostsee/Usedom/Karlshagen/25-2.-.F1.-.Weidenweg.5.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-25-2?rCH=2", "/Ostsee/Usedom/Karlshagen/25-2.-.F1.-.Weidenweg.5.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-40-2", "/Ostsee/Usedom/Karlshagen/40-2.-.F1-.Heideweg.2.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-40-2?rCH=2", "/Ostsee/Usedom/Karlshagen/40-2.-.F1-.Heideweg.2.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-44-1", "/Ostsee/Usedom/Karlshagen/44-1.-.F1.-.Heideweg.1c.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-44-1?rCH=2", "/Ostsee/Usedom/Karlshagen/44-1.-.F1.-.Heideweg.1c.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-44-4", "/Ostsee/Usedom/Karlshagen/44-4.-.F1.-.Heideweg.1.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-44-4?rCH=2", "/Ostsee/Usedom/Karlshagen/44-4.-.F1.-.Heideweg.1.b.-.2-1"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-54-6", "/Ostsee/Usedom/Karlshagen/54-6.-.F2.-.Lindenweg.2.f.-.3-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-54-6?rCH=2", "/Ostsee/Usedom/Karlshagen/54-6.-.F2.-.Lindenweg.2.f.-.3-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-57-2", "/Ostsee/Usedom/Karlshagen/57-2.-.F1.-.Lindenweg.1.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-57-2?rCH=2", "/Ostsee/Usedom/Karlshagen/57-2.-.F1.-.Lindenweg.1.b.-.2-0"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-99-1", "/Ostsee/Usedom/Karlshagen/99-1.-.F1.-.Ginsterweg.6.a.-.2-2"],
	["/duenenresidenz/ferienwohnungen-mit-1-schlafzimmer/ferienwohnung-99-1?rCH=2", "/Ostsee/Usedom/Karlshagen/99-1.-.F1.-.Ginsterweg.6.a.-.2-2"],
	["/duenenresidenz/reetdachdoppelhaushaelfte", "/s#?p_15419_id=8"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?rCH=2", "/s#?p_15419_id=8"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=108", "/Ostsee/Usedom/Karlshagen/163.-.DH.-.Laerchenweg.8.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=108&rCH=2", "/Ostsee/Usedom/Karlshagen/163.-.DH.-.Laerchenweg.8.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=117", "/Ostsee/Usedom/Karlshagen/170.-.DH.-.Kiefernweg.3.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=117&rCH=2", "/Ostsee/Usedom/Karlshagen/170.-.DH.-.Kiefernweg.3.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=118", "/Ostsee/Usedom/Karlshagen/171.-.DH.-.Kiefernweg.1.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=118&rCH=2", "/Ostsee/Usedom/Karlshagen/171.-.DH.-.Kiefernweg.1.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=130", "/Ostsee/Usedom/Karlshagen/118.-.DH.-.Birkenweg.2.b.-.4-2"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=130&rCH=2", "/Ostsee/Usedom/Karlshagen/118.-.DH.-.Birkenweg.2.b.-.4-2"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=131", "/Ostsee/Usedom/Karlshagen/119.-.DH.-.Birkenweg.2.a.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=131&rCH=2", "/Ostsee/Usedom/Karlshagen/119.-.DH.-.Birkenweg.2.a.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=61", "/Ostsee/Usedom/Karlshagen/66.-.DH.-.Sanddornweg.5.b.-.4-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=64", "/Ostsee/Usedom/Karlshagen/113.-.DH.-.Birkenweg.6.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=64&rCH=2", "/Ostsee/Usedom/Karlshagen/113.-.DH.-.Birkenweg.6.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=67", "/Ostsee/Usedom/Karlshagen/149.-.DH.-.Kiefernweg.2.b.-.5-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=67&rCH=2", "/Ostsee/Usedom/Karlshagen/149.-.DH.-.Kiefernweg.2.b.-.5-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=68", "/Ostsee/Usedom/Karlshagen/150.-.DH.-.Kiefernweg.2.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=68&rCH=2", "/Ostsee/Usedom/Karlshagen/150.-.DH.-.Kiefernweg.2.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=69", "/Ostsee/Usedom/Karlshagen/159.-.DH.-.Kiefernweg.11.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=69&rCH=2", "/Ostsee/Usedom/Karlshagen/159.-.DH.-.Kiefernweg.11.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=99", "/Ostsee/Usedom/Karlshagen/120.-.DH.-.Birkenweg.1.a.-..6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte?view=booking&id=99&rCH=2", "/Ostsee/Usedom/Karlshagen/120.-.DH.-.Birkenweg.1.a.-..6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-113", "/Ostsee/Usedom/Karlshagen/113.-.DH.-.Birkenweg.6.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-113?rCH=2", "/Ostsee/Usedom/Karlshagen/113.-.DH.-.Birkenweg.6.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-118", "/Ostsee/Usedom/Karlshagen/118.-.DH.-.Birkenweg.2.b.-.4-2"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-118?rCH=2", "/Ostsee/Usedom/Karlshagen/118.-.DH.-.Birkenweg.2.b.-.4-2"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-119", "/Ostsee/Usedom/Karlshagen/119.-.DH.-.Birkenweg.2.a.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-119?rCH=2", "/Ostsee/Usedom/Karlshagen/119.-.DH.-.Birkenweg.2.a.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-120", "/Ostsee/Usedom/Karlshagen/120.-.DH.-.Birkenweg.1.a.-..6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-120?rCH=2", "/Ostsee/Usedom/Karlshagen/120.-.DH.-.Birkenweg.1.a.-..6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-149", "/Ostsee/Usedom/Karlshagen/149.-.DH.-.Kiefernweg.2.b.-.5-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-149?rCH=2", "/Ostsee/Usedom/Karlshagen/149.-.DH.-.Kiefernweg.2.b.-.5-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-150", "/Ostsee/Usedom/Karlshagen/150.-.DH.-.Kiefernweg.2.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-150?rCH=2", "/Ostsee/Usedom/Karlshagen/150.-.DH.-.Kiefernweg.2.a.-.4-1"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-159", "/Ostsee/Usedom/Karlshagen/159.-.DH.-.Kiefernweg.11.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-159?rCH=2", "/Ostsee/Usedom/Karlshagen/159.-.DH.-.Kiefernweg.11.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-163", "/Ostsee/Usedom/Karlshagen/163.-.DH.-.Laerchenweg.8.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-163?rCH=2", "/Ostsee/Usedom/Karlshagen/163.-.DH.-.Laerchenweg.8.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-170", "/Ostsee/Usedom/Karlshagen/170.-.DH.-.Kiefernweg.3.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-170?rCH=2", "/Ostsee/Usedom/Karlshagen/170.-.DH.-.Kiefernweg.3.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-171", "/Ostsee/Usedom/Karlshagen/171.-.DH.-.Kiefernweg.1.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-171?rCH=2", "/Ostsee/Usedom/Karlshagen/171.-.DH.-.Kiefernweg.1.-.6-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-66", "/Ostsee/Usedom/Karlshagen/66.-.DH.-.Sanddornweg.5.b.-.4-0"],
	["/duenenresidenz/reetdachdoppelhaushaelfte/doppelhaus-66?rCH=2", "/Ostsee/Usedom/Karlshagen/66.-.DH.-.Sanddornweg.5.b.-.4-0"],
	["/impressum?id=14", "/impressum"],
	["/impressum?id=14&rCH=2", "/impressum"],
	["/impressum?rCH=2", "/impressum"],
	["/index.php/kontakt", "/kontakt"],
	["/index.php/kontakt?rCH=2", "/kontakt"],
	["/kontakt?rCH=2", "/kontakt"],
	["/news-angebote", "/blog"],
	["/news-angebote?rCH=2", "/blog"],
	["/preise", "/indexpage"],
	["/preise?rCH=2", "/indexpage"],
	["/service", "/ueber-uns"],
	["/service?rCH=2", "/ueber-uns"],
	["/service/freizeitangebote", "/freizeitangebote"],
	["/service/freizeitangebote?rCH=2", "/freizeitangebote"],
	["/service/partner", "/indexpage"],
	["/service/partner?rCH=2", "/indexpage"],
	["/service/veranstaltungen", "/veranstaltungen"],
	["/service/veranstaltungen?rCH=2", "/veranstaltungen"],
	["/service/webcams-karlshagen", "/indexpage"],
	["/service/webcams-karlshagen?rCH=2", "/indexpage"],
	["/unterkuenfte", "/s"],
	["/unterkuenfte?rCH=2", "/s"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg", "/s#?name=maiglöckchen"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg?rCH=2", "/s#?name=maiglöckchen"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg?view=booking&id=141", "/Ostsee/Usedom/Karlshagen/M5.-.F2.-.Am.Maigloeckchenberg.21.-.4-1"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg?view=booking&id=93", "/Ostsee/Usedom/Karlshagen/M21.-.F1.-.Am.Maigloeckchenberg.21.-.2-0"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg?view=booking&id=93&rCH=2", "/Ostsee/Usedom/Karlshagen/M21.-.F1.-.Am.Maigloeckchenberg.21.-.2-0"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg?view=category&id=26", "/s#?name=maiglöckchen"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg?view=category&id=26&rCH=2", "/s#?name=maiglöckchen"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg/ferienwohnung-m-21", "/Ostsee/Usedom/Karlshagen/M21.-.F1.-.Am.Maigloeckchenberg.21.-.2-0"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg/ferienwohnung-m-21?rCH=2", "/Ostsee/Usedom/Karlshagen/M21.-.F1.-.Am.Maigloeckchenberg.21.-.2-0"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg/ferienwohnung-m-5", "/Ostsee/Usedom/Karlshagen/M5.-.F2.-.Am.Maigloeckchenberg.21.-.4-1"],
	["/unterkuenfte/ferienwohnung-maigloeckchenberg/ferienwohnung-m-5?rCH=2", "/Ostsee/Usedom/Karlshagen/M5.-.F2.-.Am.Maigloeckchenberg.21.-.4-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse", "/s#?name=strandstrasse"],
	["/unterkuenfte/ferienwohnung-strandstrasse?rCH=2", "/s#?name=strandstrasse"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=106", "/Ostsee/Usedom/Karlshagen/26.-.F1.-.Strandstrasse.30.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=106&rCH=2", "/Ostsee/Usedom/Karlshagen/26.-.F1.-.Strandstrasse.30.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=121", "/Ostsee/Usedom/Karlshagen/10.-.F1.-.Strandstrasse.26.a.-.3-0"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=122", "/Ostsee/Usedom/Karlshagen/12.-.F1.-.Strandstrasse.26.b.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=122&rCH=2", "/Ostsee/Usedom/Karlshagen/12.-.F1.-.Strandstrasse.26.b.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=123", "/Ostsee/Usedom/Karlshagen/22.-.F2.-.Strandstrasse.30.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=123&rCH=2", "/Ostsee/Usedom/Karlshagen/22.-.F2.-.Strandstrasse.30.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=129", "/Ostsee/Usedom/Karlshagen/7.-.F2.-.Strandstrasse.30.-.3-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=129&rCH=2", "/Ostsee/Usedom/Karlshagen/7.-.F2.-.Strandstrasse.30.-.3-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=137", "/Ostsee/Usedom/Karlshagen/21.-.F2.-.Strandstrasse.30.-.4-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=139", "/Ostsee/Usedom/Karlshagen/3.-.F2.-.Strandstrasse.16.-.4-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=139&rCH=2", "/Ostsee/Usedom/Karlshagen/3.-.F2.-.Strandstrasse.16.-.4-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=89", "/Ostsee/Usedom/Karlshagen/4.-.F1.-.Strandstrasse.28.-.2-0"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=89&rCH=2", "/Ostsee/Usedom/Karlshagen/4.-.F1.-.Strandstrasse.28.-.2-0"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=90", "/Ostsee/Usedom/Karlshagen/5.-.F1.-.Strandstrasse.26.a.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=90&rCH=2", "/Ostsee/Usedom/Karlshagen/5.-.F1.-.Strandstrasse.26.a.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=91", "/Ostsee/Usedom/Karlshagen/6.-.F1-.Strandstrasse.26.a.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=91&rCH=2", "/Ostsee/Usedom/Karlshagen/6.-.F1-.Strandstrasse.26.a.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=95", "/Ostsee/Usedom/Karlshagen/18.-.F1.-.Strandstrasse.26.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=booking&id=95&rCH=2", "/Ostsee/Usedom/Karlshagen/18.-.F1.-.Strandstrasse.26.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=category&id=25", "/s#?name=strandstrasse"],
	["/unterkuenfte/ferienwohnung-strandstrasse?view=category&id=25&rCH=2", "/s#?name=strandstrasse"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-10", "/Ostsee/Usedom/Karlshagen/10.-.F1.-.Strandstrasse.26.a.-.3-0"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-10?rCH=2", "/Ostsee/Usedom/Karlshagen/10.-.F1.-.Strandstrasse.26.a.-.3-0"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-12", "/Ostsee/Usedom/Karlshagen/12.-.F1.-.Strandstrasse.26.b.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-12?rCH=2", "/Ostsee/Usedom/Karlshagen/12.-.F1.-.Strandstrasse.26.b.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-18", "/Ostsee/Usedom/Karlshagen/18.-.F1.-.Strandstrasse.26.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-18?rCH=2", "/Ostsee/Usedom/Karlshagen/18.-.F1.-.Strandstrasse.26.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-21", "/Ostsee/Usedom/Karlshagen/21.-.F2.-.Strandstrasse.30.-.4-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-21?rCH=2", "/Ostsee/Usedom/Karlshagen/21.-.F2.-.Strandstrasse.30.-.4-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-22", "/Ostsee/Usedom/Karlshagen/22.-.F2.-.Strandstrasse.30.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-22?rCH=2", "/Ostsee/Usedom/Karlshagen/22.-.F2.-.Strandstrasse.30.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-26", "/Ostsee/Usedom/Karlshagen/26.-.F1.-.Strandstrasse.30.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-26?rCH=2", "/Ostsee/Usedom/Karlshagen/26.-.F1.-.Strandstrasse.30.b.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-3", "/Ostsee/Usedom/Karlshagen/3.-.F2.-.Strandstrasse.16.-.4-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-3?rCH=2", "/Ostsee/Usedom/Karlshagen/3.-.F2.-.Strandstrasse.16.-.4-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-4", "/Ostsee/Usedom/Karlshagen/4.-.F1.-.Strandstrasse.28.-.2-0"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-4?rCH=2", "/Ostsee/Usedom/Karlshagen/4.-.F1.-.Strandstrasse.28.-.2-0"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-5", "/Ostsee/Usedom/Karlshagen/5.-.F1.-.Strandstrasse.26.a.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-5?rCH=2", "/Ostsee/Usedom/Karlshagen/5.-.F1.-.Strandstrasse.26.a.-.2-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-6", "/Ostsee/Usedom/Karlshagen/6.-.F1-.Strandstrasse.26.a.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-6?rCH=2", "/Ostsee/Usedom/Karlshagen/6.-.F1-.Strandstrasse.26.a.-.2-1"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-7", "/Ostsee/Usedom/Karlshagen/7.-.F2.-.Strandstrasse.30.-.3-2"],
	["/unterkuenfte/ferienwohnung-strandstrasse/ferienwohnung-7?rCH=2", "/Ostsee/Usedom/Karlshagen/7.-.F2.-.Strandstrasse.30.-.3-2"],
	["/unterkuenfte/fewo-duenenresidenz", "/Duenenresidenz"],
	["/unterkuenfte/fewo-duenenresidenz?rCH=2", "/Duenenresidenz"],
	["/unterkuenfte/fewo-duenenstrasse", "/s#?name=strandstrasse"],
	["/unterkuenfte/fewo-duenenstrasse?rCH=2", "/s#?name=strandstrasse"],
	["/unterkuenfte/fewo-duenenstrasse?view=booking&id=136", "/Ostsee/Usedom/Karlshagen/30..-.F2.-.Duenenstrasse.20.-.4-0"],
	["/unterkuenfte/fewo-duenenstrasse?view=booking&id=136&rCH=2", "/Ostsee/Usedom/Karlshagen/30..-.F2.-.Duenenstrasse.20.-.4-0"],
	["/unterkuenfte/fewo-duenenstrasse?view=booking&id=98", "/Ostsee/Usedom/Karlshagen/59.-.F1.-.Duenenstrasse.24.-.2-0"],
	["/unterkuenfte/fewo-duenenstrasse?view=booking&id=98&rCH=2", "/Ostsee/Usedom/Karlshagen/59.-.F1.-.Duenenstrasse.24.-.2-0"],
	["/unterkuenfte/fewo-duenenstrasse/ferienwohnung-30", "/Ostsee/Usedom/Karlshagen/30..-.F2.-.Duenenstrasse.20.-.4-0"],
	["/unterkuenfte/fewo-duenenstrasse/ferienwohnung-30?rCH=2", "/Ostsee/Usedom/Karlshagen/30..-.F2.-.Duenenstrasse.20.-.4-0"],
	["/unterkuenfte/fewo-duenenstrasse/ferienwohnung-59", "/Ostsee/Usedom/Karlshagen/59.-.F1.-.Duenenstrasse.24.-.2-0"],
	["/unterkuenfte/fewo-duenenstrasse/ferienwohnung-59?rCH=2", "/Ostsee/Usedom/Karlshagen/59.-.F1.-.Duenenstrasse.24.-.2-0"],
	["/unterkuenfte/fewo-ostseepark-duenenland", "/Ostsee/Usedom/Karlshagen/2-6.-.F2.-.Lotsenstieg.2.-.4-0"],
	["/unterkuenfte/fewo-ostseepark-duenenland?rCH=2", "/Ostsee/Usedom/Karlshagen/2-6.-.F2.-.Lotsenstieg.2.-.4-0"],
	["/unterkuenfte/fewo-ostseepark-duenenland?view=booking&id=97", "/Ostsee/Usedom/Karlshagen/2-6.-.F2.-.Lotsenstieg.2.-.4-0"],
	["/unterkuenfte/fewo-ostseepark-duenenland?view=booking&id=97&rCH=2", "/Ostsee/Usedom/Karlshagen/2-6.-.F2.-.Lotsenstieg.2.-.4-0"],
	["/unterkuenfte/fewo-ostseepark-duenenland/lotsenstieg-2-6", "/Ostsee/Usedom/Karlshagen/2-6.-.F2.-.Lotsenstieg.2.-.4-0"]
]);

// Debugging logs
// console.log("pathname", pathname);
// console.log("redirects", redirects);
// console.log("redirects.has(pathname)", redirects.has(pathname));

// Check if the current URL (pathname + search) matches any key in the redirects map

if (redirects.has(currentPath)) {
	// Redirect to the new path without the search parameters
	location.href = origin + redirects.get(currentPath);
} else if (redirects.has(pathname)) {
	// If no exact match with the search, try just the pathname
	location.href = origin + redirects.get(pathname);
}